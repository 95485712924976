import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class OrganisationService {
    paginate(index = null) {
        let url = API_URL + 'user/organisation';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(data) {
        let url = API_URL + 'user/organisation'
        return apiService.post(url, data);
    }

    createOrganisationFromStepper(data) {
        let url = API_URL + 'user/organisation/save'
        return apiService.post(url, data);
    }

    update(slug, data) {
        let url = API_URL + 'user/organisation/' + slug
        return apiService.put(url, data);
    }

    delete(slug) {
        let url = API_URL + 'user/organisation/' + slug
        return apiService.delete(url);
    }
    show(slug) {
        let url = API_URL + 'user/organisation/' + slug
        return apiService.get(url);
    }
}
