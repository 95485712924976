<template>
    <v-app>
        <div class="row">
            <div
                class="col-xl-3 col-lg-3 col-md-6 col-sm-12"
                v-if="loading"
                v-for="(item,index) in 1 "
                :key="index"
            >
                <v-skeleton-loader type="list-item-avatar, article,list-item, actions"></v-skeleton-loader>
            </div>
            <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                v-if="!loading"
                v-for="organisation in organisations"
                :key="organisation.id"
            >
                <!--begin:: Portlet-->
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <!--begin::Widget -->
                        <div class="kt-widget kt-widget--project-1">
                            <div class="kt-widget__head d-flex">
                                <div class="kt-widget__label">
                                    <div class="kt-widget__media kt-widget__media--m">
                    <span class="kt-media kt-media--md kt-media kt-hidden-">
                      <!-- <img src="/assets/media/project-logos/3.png" alt="image" /> -->
                      <img
                          v-bind:src="organisation.attribute && organisation.attribute.logo_image_path  ?  organisation.attribute.logo_image_path : '/assets/media/logos/icon-no-image.svg'"
                          :alt="organisation.title"
                          class="organisation_small_logo"
                          style="object-fit: contain;"
                      />
                    </span>
                                        <span class="kt-media kt-media--md kt-media--circle kt-hidden">
                      <img
                          v-bind:src="organisation.attribute && organisation.attribute.logo_image_path  ?  organisation.attribute.logo_image_path : '/assets/media/logos/icon-no-image.svg'"
                          alt="image"
                          style="object-fit: contain;"

                      />
                    </span>
                                    </div>
                                    <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                        <router-link
                                            class="kt-widget__title"
                                            :to="{ name: 'organization-profile', params: { name: organisation.slug }}"
                                        >{{ organisation.title }}
                                        </router-link>
                                        <span class="kt-widget__desc"></span>
                                    </div>
                                </div>
                                <div class="kt-widget__toolbar">
                                    <a
                                        href="#"
                                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                        data-toggle="dropdown"
                                    >
                                        <i class="flaticon-more-1"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                        <ul class="kt-nav">
                                            <li class="kt-nav__item">
                                                <router-link
                                                    :to="{ name: 'organization-profile', params: { name: organisation.slug }}"
                                                    class="kt-nav__link"
                                                >
                                                    <i class="kt-nav__link-icon flaticon2-line-chart"></i>
                                                    <span class="kt-nav__link-text">Manage</span>
                                                </router-link>
                                            </li>

                                            <li
                                                class="kt-nav__item"
                                                v-if="!organisation.active_cart && (!organisation.subscription.status || organisation.subscription.remaining<30)"
                                            >
                                                <a href="#" @click="showUpgradeDialog(organisation)"
                                                   class="kt-nav__link">
                                                    <i class="kt-nav__link-icon flaticon2-refresh"></i>
                                                    <span class="kt-nav__link-text">Upgrade</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item" v-if="!organisation.deleted_at">
                                                <a
                                                    href="#"
                                                    @click="deleteOrganisation(organisation.slug)"
                                                    class="kt-nav__link"
                                                >
                                                    <i class="kt-nav__link-icon flaticon2-trash"></i>
                                                    <span class="kt-nav__link-text">Delete</span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item" v-if="organisation.deleted_at">
                                                <a
                                                    href="#"
                                                    @click="recoverOrganisation(organisation.slug)"
                                                    class="kt-nav__link"
                                                >
                                                    <i class="kt-nav__link-icon flaticon2-trash"></i>
                                                    <span class="kt-nav__link-text">Cancel Delete</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-widget__body align-items-center">
                <span v-for="item in organisation.addresses" :key="item.id">
                  <p class="text-left" v-if="item.is_primary">
                    <i class="flaticon2-placeholder"></i>
                    {{ (item.address_line_1)?item.address_line_1+', ':'' }}
                    {{ (item.area)?item.area+",":'' }}
                    {{ (item.city)?item.city+',':'' }}
                    {{ (item.state)?item.state+"":'' }}
                  </p>
                </span>
                                <p class="text-left">
                                    <i class="flaticon2-new-email"></i>
                                    {{ organisation.email }}
                                </p>
                                <p class="text-left">
                                    <i class="flaticon2-phone"></i>
                                    {{ organisation.phone }}
                                </p>
                                <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                                    <span
                                        class="kt-widget__date kt-padding-0 kt-margin-r-10">Subscription Expiry Date</span>
                                    <div class="kt-widget__label" v-if="organisation.subscription">
                                        <span v-if="organisation.subscription.expire_at" class="btn btn-label-danger btn-sm btn-bold btn-upper">{{organisation.subscription.expire_at}}</span>
                                        <span v-else class="btn btn-label-danger btn-sm btn-bold btn-upper">FREE</span>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-widget__footer">
                                <div class="kt-widget__wrapper">
                                    <div class="kt-widget__section">
                                        <div class="kt-widget__blog">
                                            <i class="fa fa-eye"></i>
                                            <a href="#" class="kt-widget__value kt-font-brand"> {{ organisation.views ?
                                                organisation.views : 'N/A'}} Views</a>
                                        </div>

                                        <!-- <div class="kt-widget__blog pl-2">
                                          <i class="fa fa-star"></i>
                                          <a href="#" class="kt-widget__value kt-font-brand">Reviews</a>
                                        </div> -->
                                        <!-- <div class="kt-widget__blog pl-2">
                                          <i class="fa fa-envelope"></i>
                                          <a href="#" class="kt-widget__value kt-font-brand">500 Inquiry</a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--end::Widget -->
                    </div>
                </div>

                <!--end:: Portlet-->
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower">
                    <div class="kt-portlet__body">
                        <div class="kt-iconbox__body">
                            <div class="kt-iconbox__icon">
                                <i class="flaticon2-box-1 kt-font-success kt-font-big" style="font-size:60px"></i>
                            </div>
                            <div class="kt-iconbox__desc">
                                <h3 class="kt-iconbox__title">
                                    <router-link to="organizations/new" class="kt-link">Add a new business</router-link>
                                </h3>
                                <div class="kt-iconbox__content">
                                    <br/>Add business to our classified directory
                                    <hr/>
                                    <div class="v-card__actions pull-right">
                                        <button
                                            type="button"
                                            class="btn btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right btn-secondary">
                                            <router-link to="organizations/new" class="v-btn__content">Add Business
                                            </router-link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import OgranisationService from "@/services/organisation/OrganisationService";

    const ogranisationService = new OgranisationService();

    export default {
        name: "OrganizationList",
        components: {},
        comments: {},
        data: function () {
            return {
                loading: true,
                organisation_id: null,
                organisations: [],
                membershipDialog: false
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Organisation List", route: "organizations"}
            ]);
            this.getOrganisations();
        },
        methods: {
            showUpgradeDialog(item) {
                this.organisation_id = item.id;
                this.membershipDialog = true;
            },

            closeUpgradeDialog() {
                this.membershipDialog = false;
                this.getOrganisations();
            },
            getOrganisations() {
                ogranisationService
                    .paginate()
                    .then(res => {
                        this.organisations = res.data.data;
                        this.loading = false;
                    })
                    .catch(err => {
                    });
            },
            deleteOrganisation: function (slug) {
                this.$confirm(
                    {
                        message: `Confirm delete organisation ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                ogranisationService
                                    .delete(slug)
                                    .then(res => {
                                        this.faqdialog = false;
                                        this.$snotify.success("Organisation scheduled to delete");
                                        this.getOrganisations();
                                    })
                                    .catch(err => {
                                    });
                            }
                        }
                    }
                )

            },
            recoverOrganisation: function (slug) {
                this.$confirm(
                    {
                        message: `Confirm restore organisation ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                let data = {deleted_at: null};
                                ogranisationService
                                    .update(slug, data)
                                    .then(res => {
                                        this.$snotify.success("Organisation restored");
                                        this.getOrganisations();
                                    })
                                    .catch(err => {
                                    });
                            }
                        }
                    }
                )

            }
        }
    };
</script>


